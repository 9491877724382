import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic1.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic2.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic3.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic4.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic5.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic6.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic7.jpeg",
  "https://chinmayavvdelhi.ac.in/assets/images/calendar/26-july-1/pic8.jpeg",
];

export default function KargilVijayDiwas() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Kargil Vijay Diwas - A Symbol of Pride and Glory
        </h1>

        <p>
          Chinmaya Vidyalaya, New Delhi organized a special assembly on Kargil
          Vijay Diwas to pay tribute to the brave soldiers who fought valiantly
          during the Kargil War. The assembly showcased an impactful discussion
          on the essence of Kargil Diwas, a powerful song “Taaqat Watan Ki Hum
          Mein Hai”, an enactment of the podcast ‘Echoes of Kargil’ and a poem
          that celebrated the bravery and sacrifices of our patriotic heroes.
          The students pledged to uphold the integrity and unity of the nation
          and to stand with the armed forces in times of need. The students of
          the Primary Department were shown a video presentation highlighting
          the significance of Kargil Diwas and some important information
          associated with the day. The students made gratitude cards for
          soldiers and collages on the diet and healthy food habits of soldiers.
          They also made a soldier's tricolor cap.
          <br />
          <br />
          The Principal, Ms Archana Soni, delivered an inspiring talk, wherein
          she expressed her gratitude and respect for the families of the army
          personnel. She acknowledged the sacrifices made not only by the
          soldiers but also by their families.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
